import BasePlugin from '../BasePlugin'

export default class OpenProjectFromStartingDashboard extends BasePlugin {
  async execute (row, column, cell) {
    if (column.property === 'attr_4262_' && row.attr_4229_id) {
      this.context.$parent.openRegistryCard({
        registryId: 87,
        cardId: 143,
        cardName: 'Проект',
        recordId: row.attr_4229_id
      })
    }
  }
}
